import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  const [value, setValue] = useState('');
  const [isEmpty, setIsEmpty] = useState(false);
  const [verifiedData, setVerifiedData] = useState(false);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    const newLength = new Blob([newValue]).size;

    if (newLength <= 80) {
      setValue(newValue);
      setIsEmpty(newValue.length === 0);
    }
  };

  const handleSubmit = () => {
    if (value.length === 0) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
      
      const inputData = { data: value };
      
      fetch('https://v515iup4eb.execute-api.eu-west-2.amazonaws.com/Test-env', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(inputData),
      })
      .then(response => response.json())
      .then(data => {
        const checkoutLink = data.checkoutLink;
        if (checkoutLink) {
          setVerifiedData(true);
          window.open(checkoutLink, '_blank');
        } else {
          console.error('Checkout link not found in response');
        }
      })
      .catch(error => {
        console.error('Error making the API call:', error);
      });
    }
  };

  return (
    <div className="home-container">
      <a href="/" className="bitcoinproofer-title"><h1>₿itcoinProofer</h1></a>
      <div className="input-container">
        <input 
          type="text" 
          placeholder="Enter some data to store on the Bitcoin blockchain…" 
          className={`input-field ${isEmpty ? 'input-field-error' : ''}`}
          value={value}
          onChange={handleInputChange}
        />
        <button className="submit-button" onClick={handleSubmit}>Verify and submit</button>
      </div>
      {verifiedData && (
      <div className="verified-data-message"><p>
        Proofing data onto the Bitcoin blockchain 🔒…
        Once payment is confirmed, the supplied data will be broadcasted to the Bitcoin network.
        You can see all the BitcoinProofer testnet proofing transactions <a href="https://blockstream.info/testnet/" target="_blank">here</a>.
        </p>
      </div>
    )}
      <Link to="/about" className="how-it-works-link">About</Link>
    </div>
  );
}

export default Home;