import React from 'react';

const HowItWorks = () => {
  return (
    <div className="how-it-works-page">
        <h1>About</h1>
      <div className="how-it-works-container">
        <p>BitcoinProofer is a notarisation and timestamping service that allows you to prove the existence of any data such as documents, text, images, files etc at a specific point in time by storing a hash of that data on the bitcoin blockchain.</p>
        <p>A cryptographic <a href="https://en.wikipedia.org/wiki/Cryptographic_hash_function" target="_blank" rel="noopener noreferrer">hash</a> is a unique fingerprint of the data that can be used to verify data integrity.</p>
        <p>We recommend using the SHA-256 hash function for security. Once you have have produced a hash of the data you must not modify the data again otherwise the hash will change. </p>
        <p>A SHA-256 hash of "hello world" looks like this: <em>b94d27b9934d3e08a52e52d7da7dabfac484efe37a5380ee9088f7ace2efcde9</em></p>
        <p>Once you have the hash you can enter it in the input field and click the "Verify and submit" button. You will then be prompted to pay the current bitcoin market transaction fee in order to store the data on the blockchain.</p>
        <p>Payments are currently accepted using the Lightning Network or Bitcoin only. Lightning Network payments are recommended as they are negligible in cost and process very quickly.</p>
        <hr />
        <p>This service is a work in progress and is not yet fully functional. <span className="orange-text">It is currently only available for testing on bitcoin testnet</span>. Please check back later for updates.</p>
        <p>Contact: <strong>support@bitcoinproofer.com</strong></p>
      </div>
    </div>
  );
}

export default HowItWorks;
